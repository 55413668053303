import React, { useEffect } from 'react'

import 'twin.macro'

import SEO from 'components/shared/seo'
import { useCxHardware } from 'content-queries/cx/hardware'
import fireEvent from 'context/tracking/events'
import Hero from 'mx/components/hero'
import { SectionContent } from 'mx/components/section-content'
import { SpecsSection } from 'mx/components/specs-section'
import Layout from 'mx/layout'

const Hardware = () => {
  const { data } = useCxHardware()

  useEffect(() => {
    fireEvent({
      type: 'page_viewed',
      pageTitle: 'FORM Catalog - Hardware',
    })
  }, [])

  const [
    {
      node: {
        sectionModules: [
          hero,
          sensysModule,
          motionModule,
          strongModule,
          sidePanelsModule,
          assemblyModule,
          aventosModule,
        ],
      },
    },
  ] = data
  return (
    <div tw="relative">
      <Layout>
        <Hero data={hero} />
        <SpecsSection data={sensysModule} />
        <SectionContent data={motionModule} />
        <SectionContent data={strongModule} />
        <SectionContent data={sidePanelsModule} />
        <SectionContent data={assemblyModule} />
        <SpecsSection data={aventosModule} />
      </Layout>
    </div>
  )
}

export default Hardware

export const Head = () => (
  <SEO
    description="Discover our new modern kitchen cabinet hardware, from award-winning Hettich and Blum options."
    title="Catalog - Hardware"
  />
)
